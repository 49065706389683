import React, {Component} from 'react';
import { Redirect }       from 'react-router-dom';
import uuidv4             from 'uuid/v4';
import moment             from 'moment';
import _                  from 'lodash';
import API                from 'API';

import {
    Dialog, 
    DialogActions, 
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    InputAdornment,
    Typography,
    Grid,
    FormControl,
    Button,
    TextField,
    Link,
    Checkbox,
    ListItemText,
    ListItemAvatar,
    ListItem,
    List,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import SnackBar                from 'Components/Common/SnackBars/SnackBar';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import FALightIcon             from 'Components/Common/Icons/FontAwesome/FALightIcon';
import AddressLookup           from 'Components/Common/AddressLookup/AddressLookup';
import ConfirmationDialog      from 'Components/Common/Dialogs/ConfirmationDialog';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AllIcon                 from 'Components/Common/Icons/AllIcon';
import CheckBox                from 'Components/Common/Inputs/CheckBox';

import AddressCard from './AddressCard';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import {colors}                   from 'Helpers/ColourHelper';
import { getZonesFromPostcode }   from 'Helpers/AddressHelper';
import icons                      from 'Helpers/IconHelper';

import {specialCharacterWhiteList} from 'Constants';

const initialState = () => ({ 
    formId: uuidv4(), 
    couriers: [],
    formData: {
        // Business Details
        businessType:               '',
        businessName:               '',
        tradingName:                '',
        legalStatus:                '',
        companyRegistrationNumber:  '',
        companyVATNumber:           '',
        companyEORINumber:          '',
        // link customers 
        customers:                  [],
        // Account Information
        vatRate:                    '',
        discountRate:               '',
        paymentTerm:                '',
        accountStatus:              '',
        monthlyServicePlan:         null,
        yearlyServicePlan:          null,
        // Business Registered Address
        selectedAddress:            null,
        addressOne:                 '',
        addressTwo:                 '',
        addressThree:               '',
        town:                       '',
        county:                     '',
        country:                    243,
        postcode:                   '',
        phone:                      '',
        email:                      '',
        website:                    '',
        showAddress:                false,
        showAddressLookup:          true,
        creditLimitNet:             0,
        creditLimitGross:           0,
        zones:                      [],
        // Trading Addresses
        tradingAddresses: [
            {
                name:                   '',
                addressOne:             '',
                addressTwo:             '',
                addressThree:           '',
                town:                   '',
                county:                 '',
                country:                243,
                postcode:               '',
                phone:                  '',
                email:                  '',
                showAddress:            false,
                showAddressLookup:      true,
                useAddress:             'custom',
                zones:                  []
            }
        ],
        // Accounts Addresses
        accountsAddresses: [
            {
                name:                   '',
                addressOne:             '',
                addressTwo:             '',
                addressThree:           '',
                town:                   '',
                county:                 '',
                country:                243,
                postcode:               '',
                phone:                  '',
                email:                  '',
                showAddress:            false,
                showAddressLookup:      true,
                useAddress:             'custom',
                zones:                  []
            }
        ],
        // Site Addresses
        siteAddresses: [
            {
                name:                   '',
                addressOne:             '',
                addressTwo:             '',
                addressThree:           '',
                town:                   '',
                county:                 '',
                country:                243,
                postcode:               '',
                phone:                  '',
                email:                  '',
                showAddress:            false,
                showAddressLookup:      true,
                useAddress:             'custom',
                accessTimes: [{
                    day:                    '',
                    startTime:              '00:00',
                    endTime:                '00:00'
                }],
                addAccessTimes:         false,
                openingTimes: [{
                    day:                    '',
                    startTime:              '00:00',
                    endTime:                '00:00'
                }],
                openingTimeNotes:       '',
                accessTimeNotes:        '',
                addOpeningTimes:        false,
                linkedAccount:          0,
                zones:                  []
            }
        ],
        marketing: [44],
    },
    countyList:                     [],
    countryList:                    [],
    vatList:                        [],
    discountList:                   [],
    paymentTermsList:               [],
    accountStatusList:              [],
    tradingList:                    [],
    formErrors:                     [],
    confirmationOpen:               false,
    addContactOpen:                 false,
    snackbarOpen:                   false,
    isLoading:                      false,
    confirmationMsg:                [],
    addressNameList:                [],
    redirect:                       false,
    customerId:                     '',
    access:                         {},
    lists:                          {
        customers:  [],
        marketing:  [],
    }
})

const styles = theme => ({
    paperGrey: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
        backgroundColor:'#fefefe'
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AddCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount(){
        this.populateDropdowns();
        this.checkAccess(); 
        this.getCustomers();
        this.getCouriers();
        this.getMarketing();
    }

    getMarketing = () => {
        API.get('/marketing/customerMarketing').then(res => {
            this.setState({
                lists: {
                    ...this.state.lists,
                    marketing: res.data
                }
            })
        })
    }

    getCouriers(){
        API.get('/couriers').then(res => {
            this.setState({ couriers: res.data.couriers });
        })
    }

    getLogoSrc = files => {
        let logo = _.find(files, {'cour_file_type': 'Logo'});
        return logo ? logo.file_url : '';
    }

    getCustomers = () => {
        API.get('/customers')
        .then(res => {
            this.setState({
                lists: {
                    ...this.state.lists,
                    customers: _.map(res.data, i => _.assign({
                        value: i.cust_id,
                        label: `${i.cust_name} | CS${i.cust_id} | ${_.find(i.addresses, {address_type: 'Registered'}).address_postcode}`
                    }) )
                }
            })
        })
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-customer-credit-limit'),
            API.get('/staff/my/access/check/update-customer-credit-limit'),
        ]).then(([viewCreditLimit, updateCreditLimit]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    viewCreditLimit:    viewCreditLimit.data    ? viewCreditLimit.data.has_access       : false,
                    updateCreditLimit:  updateCreditLimit.data  ? updateCreditLimit.data.has_access     : false,
                }
            });
        });
    }
    populateDropdowns = () => {
        let countyList = [];
        let countryList = [];
        let vatList = [];
        let discountList = [];
        let paymentTermsList = [];
        let accountStatusList = [];
        Promise.all([
            API.get('/misc/counties/all'),
            API.get('/misc/countries/all'),
            API.get('/accounts/vatRates/byType'),
            API.get('/accounts/customerDiscounts/byName'),
            API.get('/customers/paymentTerms'),
            API.get('/customers/accountStatuses')
        ])
        .then(([countyRes, countryRes, vatRes, discountRes, paymentTermRes, accountRes]) =>  {
            if(countyRes.data){
                countyList = _.map(countyRes.data, el => {
                    return _.assign({
                        value: el.county_id,
                        label: el.county_name,
                    });
                });
            }
            if(countryRes.data){
                countryList = _.map(countryRes.data, el => {
                    return _.assign({
                        value: el.country_id,
                        label: el.country_name,
                    });
                });
            }
            if(vatRes.data){
                vatRes.data.forEach((type) => {
                    let currentValue = _.find(type.vat_values, {'current': true});
                    if(currentValue){
                        vatList.push({
                            value:      type.vat_type_id,
                            label:      type.vat_type_name + ' | ' + currentValue.vat_value_percentage + '%',
                            decimal:    parseFloat(currentValue.vat_value_percentage) / 100
                        });
                    }
                });
            }
            if(discountRes.data){
                discountRes.data.forEach((name) => {
                    let currentValue = _.find(name.discount_values, {'current': true});
                    if(currentValue){
                        discountList.push({
                            value: name.cdn_id,
                            label: name.cdn_name + ' | ' + currentValue.cdv_percentage + '%',
                        });
                    }
                });
            }
            if(paymentTermRes.data){
                paymentTermsList = _.map(paymentTermRes.data, el => {
                    return _.assign({
                        value: el.payment_term_id,
                        label: el.payment_term_name,
                    });
                });
            }
            if(accountRes.data){
                accountStatusList = _.map(accountRes.data, el => {
                    return _.assign({
                        value: el.cas_id,
                        label: el.cas_name
                    });
                });
            }

            this.setState({
                countyList:         countyList,
                countryList:        countryList,
                vatList:            vatList,
                discountList:       discountList,
                paymentTermsList:   paymentTermsList,
                accountStatusList:  accountStatusList
            });
        });
    }
    handleChange = (e) => { 
        if (e.target.name === 'creditLimitNet') {            
            let vat = _.find(this.state.vatList,{value: this.state.formData.vatRate})?.decimal || 0;
            this.setState({
                formData: {
                    ...this.state.formData,
                    creditLimitNet:     e.target.value,
                    creditLimitGross:   parseFloat( parseInt(e.target.value) + ( e.target.value * vat ) ).toFixed(0)
                }
            });
        } else {
            let name = e.target.name;
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: e.target.value
                }
            }, () => {
                if(name === 'businessName' || name === 'tradingName'){
                    let addressNames = [{
                                            value: this.state.formData.businessName,
                                            label: this.state.formData.businessName,
                                        },
                                        {
                                            value: this.state.formData.tradingName,
                                            label: this.state.formData.tradingName,
                                        }]
                    this.setState({
                        addressNameList: addressNames
                    });
                }  
                this.checkForErrors();
            });
        }
    }

    checkZones = async (idx=null, type=null) => {
        let zones = await getZonesFromPostcode( type ? this.state.formData[type][idx].postcode : this.state.formData.postcode);
        if (type){
            let addresses = this.state.formData[type];
            if (addresses[idx].country !== 243) return;
            addresses[idx].zones = _.uniqBy([...zones,...addresses[idx].zones], i => i.courier);
            this.setState({
                formData: {
                    ...this.state.formData,
                    [type]: addresses
                }
            });
        } else {
            if (this.state.formData.country !== 243) return;
            this.setState({
                formData: {
                    ...this.state.formData,
                    zones: _.uniqBy([...zones, ...this.state.formData.zones], i => i.courier)
                }
            });
        }
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            if(fieldName === 'country'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        county: ''
                    }
                })
            }
            if (fieldName === 'vatRate')
                this.handleChange({target:{name:'creditLimitNet', value: this.state.formData.creditLimitNet}});
        });
    }
    //Access times
    handleAddAccessTimes = idx => type => e => {
        let siteAddresses = [...this.state.formData.siteAddresses]
        siteAddresses[idx] = {
            ...siteAddresses[idx],
            [type]: JSON.parse(e.target.value)
        }
        this.setState({
            formData:{
                ...this.state.formData,
                siteAddresses
            }
        });
    }
    handleDayChange = type => (addressIdx, idx) => day => {
        let newDay = [...this.state.formData.siteAddresses[addressIdx][type]];
        newDay[idx] = {
            ...newDay[idx],
            day: day && day.value
        }
        let siteAddresses = [...this.state.formData.siteAddresses];
        siteAddresses[addressIdx] = {
            ...siteAddresses[addressIdx],
            [type]: newDay
        }
        this.setState({
            formData: {
                ...this.state.formData,
                siteAddresses
            }
        });
    }
    handleTimeChange = (type) => (fieldName, addressIdx, idx) => time => {
        let newTime = [...this.state.formData.siteAddresses[addressIdx][type]];
        newTime[idx] = {
            ...newTime[idx],
            [fieldName]: time && moment(time).format('HH:mm')
        }
        let siteAddresses = [...this.state.formData.siteAddresses];
        siteAddresses[addressIdx] = {
            ...siteAddresses[addressIdx],
            [type]: newTime
        }
        this.setState({
            formData: {
                ...this.state.formData,
                siteAddresses
            }
        });
    }
    handleAddAccessTimeRow = type => addressIdx => {
        let item = initialState().formData.siteAddresses[0][type][0];
        let siteAddresses = [...this.state.formData.siteAddresses];
        siteAddresses[addressIdx] = {
            ...siteAddresses[addressIdx],
            [type]: [...this.state.formData.siteAddresses[addressIdx][type], item]
        }
        this.setState({
            formData:{
                ...this.state.formData,
                siteAddresses
            }
        });
    }
    handleNoteChange = idx => (type) => (e) => {
        let siteAddresses = this.state.formData.siteAddresses;
        siteAddresses[idx][type] = e ? e.target.value : '';
        this.setState({
            formData:{
                ...this.state.formData,
                siteAddresses
            }
        });
    }
    handleRemoveRow = type => (addressIdx, idx) => () => {
        const accessTimes = [...this.state.formData.siteAddresses[addressIdx][type]];
        accessTimes.splice(idx, 1);
        let siteAddresses = [...this.state.formData.siteAddresses];
        siteAddresses[addressIdx] = {
            ...siteAddresses[addressIdx],
            [type]: accessTimes
        }
        this.setState({
            formData:{
                ...this.state.formData,
                siteAddresses
            }
        });
    }
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }
    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/customers', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){
                    // If error then show address fields
                    let tradingAddresses =  [...this.state.formData.tradingAddresses];
                    tradingAddresses.forEach((add, idx) => {
                        tradingAddresses[idx] = {
                            ...tradingAddresses[idx],
                            showAddress: true,
                            showAddressLookup: false
                        }
                    });
                    let accountsAddresses =  [...this.state.formData.accountsAddresses];
                    accountsAddresses.forEach((add, idx) => {
                        accountsAddresses[idx] = {
                            ...accountsAddresses[idx],
                            showAddress: true,
                            showAddressLookup: false
                        }
                    });
                    let siteAddresses =  [...this.state.formData.siteAddresses];
                    siteAddresses.forEach((add, idx) => {
                        siteAddresses[idx] = {
                            ...siteAddresses[idx],
                            showAddress: true,
                            showAddressLookup: false
                        }
                    });

                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false,
                        formData: {
                            ...this.state.formData,
                            showAddress: true,
                            showAddressLookup: false,
                            accountsAddresses: accountsAddresses,
                            tradingAddresses: tradingAddresses,
                            siteAddresses: siteAddresses
                        }
                    });
                }
                else {
                    this.setState({
                        ..._.cloneDeep(initialState()),
                        addContactOpen: true,
                        formId: (initialState().formId + 1),
                        snackbarOpen: true,
                        customerId: result.data.customerId
                    });
                    this.populateDropdowns();
                    this.getMarketing();
                }
                this.props.scrollToTop();
            });
        });
    }
    handleConfirmationOpen = (e) => {
        API.get('/customers/checkExistingNames', { params: { customerName: this.state.formData.businessName, tradingName: this.state.formData.tradingName } })
        .then((result) => {

            this.setState({
                confirmationOpen: true,
                confirmationMsg: result.data
            });
        });
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleAddContactClose = () => {
        this.setState({ 
            addContactOpen: false,
            customerId: ''
        });
    }
    handleAddContactSuccess = () => {
        this.setState({
            redirect: true
        });
    }


    handleCheckChange = (idx, name) => e => {
        const value = e.target.value;
        let arr =  [...this.state.formData[name]];
        if(value === 'registered'){
            arr[idx] = {
                ...arr[idx],
                addressOne: this.state.formData.addressOne,
                addressTwo: this.state.formData.addressTwo,
                addressThree: this.state.formData.addressThree,
                town: this.state.formData.town,
                county: this.state.formData.county,
                country: this.state.formData.country,
                postcode: this.state.formData.postcode,
                phone: this.state.formData.phone,
                email: this.state.formData.email,
                showAddress: true,
                showAddressLookup: false,
                useAddress: value,
                zones: this.state.formData.zones
            };
        }
        else if(value === 'trading'){
            arr[idx] = {
                ...arr[idx],
                addressOne: '',
                addressTwo:  '',
                addressThree:  '',
                town:  '',
                county: '',
                country:  243,
                postcode: '',
                phone:'',
                email: '',
                useAddress: value,
                zones: []
            };
        }
        else {
            arr[idx] = {
                ...arr[idx],
                addressOne: '',
                addressTwo:  '',
                addressThree:  '',
                town:  '',
                county: '',
                country:  243,
                postcode: '',
                phone:'',
                email: '',
                showAddress: false,
                showAddressLookup: true,
                useAddress: value,
                zones: []
            };
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: arr
            }
        },
        () => {
            this.populateTradingList(); 
        });
    }; 

    handleAddTrading = () => {
        let item = _.cloneDeep(initialState().formData.tradingAddresses[0]);
        this.setState({
            formData:{
                ...this.state.formData,
                tradingAddresses: [...this.state.formData.tradingAddresses, item]
            }
        });
    }
    handleAddAccounts = () => {
        let item = _.cloneDeep(initialState().formData.accountsAddresses[0]);
        this.setState({
            formData:{
                ...this.state.formData,
                accountsAddresses: [...this.state.formData.accountsAddresses, item]
            }
        });
    }
    handleAddSites = () => {
        let item = _.cloneDeep(initialState().formData.siteAddresses[0]);
        this.setState({
            formData:{
                ...this.state.formData,
                siteAddresses: [...this.state.formData.siteAddresses, item]
            }
        });
    }

    handleRemoveAddress = (idx, addressType) => () => {
        const addresses = [...this.state.formData[addressType]]
        addresses.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [addressType]: addresses
            }
        }, () => {
            if (addressType === 'accountsAddresses') this.resetLinkedSites();
        })
    }
    
    resetLinkedSites = () => {
        this.setState({
            formData:{
                ...this.state.formData,
                siteAddresses: _.map(this.state.formData.siteAddresses, i => {
                    if (!this.state.formData.accountsAddresses[i.linkedAccount]) i.linkedAccount = 0;
                    return i;
                })
            }
        })
    }

    handleAddressChange = (idx, name, addressType) => e => {
        let val = e.target ? e.target.value : e.value;
        let addresses =  [...this.state.formData[addressType]];
        addresses[idx] = {
            ...addresses[idx],
            [name]: val
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [addressType]: addresses
            }
        },
        () => {
            if(addressType === 'tradingAddresses'){
                this.populateTradingList();
            }
            /* If country changed then clear the county */
            if(name === 'country'){
               
                let addresses =  [...this.state.formData[addressType]];
                addresses[idx] = {
                    ...addresses[idx],
                    county: '',
                };
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [addressType]: addresses
                    }
                });
            }
            this.checkForErrors();
        });
    }
    handleAddressSelectChange = (idx, name, addressType) => selectedOption => {
        let val = selectedOption && selectedOption.value;
        let addresses =  [...this.state.formData[addressType]];
        addresses[idx] = {
            ...addresses[idx],
            [name]: val,
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [addressType]: addresses
            }
        });
    }
    populateTradingList(){
        let tradingList = _.map(this.state.formData.tradingAddresses, el => {
            return _.assign({
                value: el,
                label: el.addressOne + ', ' + el.town,
            });
        });
        this.setState({
            tradingList: tradingList
        });
        
    }
    handleSelectedAddress = (idx, name) => e => {
        let addresses =  [...this.state.formData[name]];

        if(e && e.value){
            addresses[idx] = {
                ...addresses[idx],
                addressOne: e.value.line1,
                addressTwo:  e.value.line2,
                addressThree:  e.value.line3,
                town:  e.value.town,
                county: e.value.county,
                postcode: e.value.postcode,
                showAddress: true,
                selectedAddress: e
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: addresses
                }
            },
            () => {
               this.populateTradingList(); 
            });
        }
        else {
            addresses[idx] = {
                ...addresses[idx],
                selectedAddress: null
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: addresses
                }
            },
            () => {
               this.populateTradingList(); 
            });
        }
    }
    
    handleRegisteredSelectedAddress = e => {
        if(e && e.value){
            this.setState({
                formData: {
                    ...this.state.formData,
                    addressOne: e.value.line1,
                    addressTwo:  e.value.line2,
                    addressThree:  e.value.line3,
                    town:  e.value.town,
                    county: e.value.county,
                    postcode: e.value.postcode,
                    showAddress: true,
                    selectedAddress: e,
                }
            });
        }
        else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    selectedAddress: null
                }
            });
        }
    }
    useTradingAddress = (idx, addressType) => e => {
        if(e && e.value){
            let val = e.value;
            let addresses =  [...this.state.formData[addressType]];
            addresses[idx] = {
                ...addresses[idx],
                addressOne: val.addressOne,
                addressTwo: val.addressTwo,
                addressThree: val.addressThree,
                town: val.town,
                county: val.county,
                country: val.country,
                postcode: val.postcode,
                phone: val.phone,
                email: val.email,
                showAddress: true,
                zones: val.zones,
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    [addressType]: addresses
                }
            });
        }
    }

    handleManuallyEnterRegisteredAddress = e => {
        e.preventDefault();
        
        this.setState({
            formData: {
                ...this.state.formData,
                showAddress:        this.state.formData.showAddress && this.state.formData.showAddressLookup ? true : !this.state.formData.showAddress,
                showAddressLookup:  this.state.formData.showAddress && this.state.formData.showAddressLookup ? false : !this.state.formData.showAddressLookup,
                addressOne:         this.state.formData.showAddress ? '' : this.state.formData.addressOne,
                addressTwo:         this.state.formData.showAddress ? '' : this.state.formData.addressTwo,
                addressThree:       this.state.formData.showAddress ? '' : this.state.formData.addressThree,
                town:               this.state.formData.showAddress ? '' : this.state.formData.town,
                county:             this.state.formData.showAddress ? '' : this.state.formData.county,
                postcode:           this.state.formData.showAddress ? '' : this.state.formData.postcode
            }
        });
    }

    handleLinkAccount = idx => e => {
        let siteAddresses = this.state.formData.siteAddresses;
        siteAddresses[idx].linkedAccount = e.value;
        this.setState({
            formData: {
                ...this.state.formData,
                siteAddresses
            }
        });
    }

    handleManuallyEnterAddress = (idx, name) => e => {
        e.preventDefault();

        let addresses =  [...this.state.formData[name]];
        // if showAddress is open because of the address lookup then when manual is pressed, clear everything
        addresses[idx] = {
            ...addresses[idx],
            showAddress:        addresses[idx].showAddress && addresses[idx].showAddressLookup ? true : !addresses[idx].showAddress,
            showAddressLookup:  addresses[idx].showAddress && addresses[idx].showAddressLookup ? false : !addresses[idx].showAddressLookup,
            addressOne:         addresses[idx].showAddress ? '' : addresses[idx].addressOne,
            addressTwo:         addresses[idx].showAddress ? '' : addresses[idx].addressTwo,
            addressThree:       addresses[idx].showAddress ? '' : addresses[idx].addressThree,
            town:               addresses[idx].showAddress ? '' : addresses[idx].town,
            county:             addresses[idx].showAddress ? '' : addresses[idx].county,
            postcode:           addresses[idx].showAddress ? '' : addresses[idx].postcode
        };
        
        
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: addresses
            }
        });
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleCourierCheck = (idx, addressType) => id => {
        // let addresses =  [...this.state.formData[addressType]];
        // let data = addresses[idx].zones;

        let data, addresses;

        if (addressType === 'registered') {
            addresses = _.cloneDeep(this.state.formData);
            data = addresses.zones;
        } else {
            addresses = _.cloneDeep(this.state.formData[addressType]);
            data =  addresses[idx].zones;
        }

        let iidx  = _.findIndex(data, {courier: id});
        if (iidx >= 0) {
            data.splice(iidx, 1);
        } else {
            data.push({courier: id, zone: 0});
        }

        if (addressType === 'registered') {
            this.setState({
                formData: {
                    ...this.state.formData,
                    zones: data
                }
            });
        } else {
            addresses[idx].zones = data;
            this.setState({
                formData: {
                    ...this.state.formData,
                    [addressType]: addresses
                }
            });
        }
    }
    
    handleCourierSelect = (idx, addressType) => id => e => {
        let value = e ? e.value : null;
        
        if (addressType === 'registered') {
            let address = this.state.formData;
            let zidx  = _.findIndex(address.zones, {courier: id});
            address.zones[zidx].zone = value;
            this.setState({
                formData: address
            });
        } else {
            let addresses =  [...this.state.formData[addressType]];
            let zidx  = _.findIndex(addresses[idx].zones, {courier: id});
            addresses[idx].zones[zidx].zone = value;
            this.setState({
                formData: {
                    ...this.state.formData,
                    [addressType]: addresses
                }
            });
        }
    }

    doesStringContainNonAlphanumeric = (textString) => {
        const regex = specialCharacterWhiteList;
        return regex.test(textString);
    };

    checkForErrors = () => {
        let { formData, formErrors, specialCharacterError } = this.state;
       
        if (formData.businessName) formErrors['businessName'] = (this.doesStringContainNonAlphanumeric(formData.businessName)) ? 'Business name contains invalid characters' : null;
        if (formData.tradingName)  formErrors['tradingName']  = (this.doesStringContainNonAlphanumeric(formData.tradingName))  ? 'Trading name contains invalid characters'  : null;

        specialCharacterError = (formErrors['businessName'] || formErrors['tradingName']) ? true : false;

        _.each(formData.tradingAddresses,  (i, idx) => { 
            if (i.name) formErrors[`tradingAddresses|name|${idx}`]  = (this.doesStringContainNonAlphanumeric(i.name)) ? 'Address name contains invalid characters' : null;
            specialCharacterError =  formErrors[`tradingAddresses|name|${idx}`] ? true : specialCharacterError;
        });
        _.each(formData.siteAddresses,     (i, idx) => { 
            if (i.name) formErrors[`siteAddresses|name|${idx}`]     = (this.doesStringContainNonAlphanumeric(i.name)) ? 'Address name contains invalid characters' : null;
            specialCharacterError =  formErrors[`siteAddresses|name|${idx}`] ? true : specialCharacterError;
        });
        _.each(formData.accountsAddresses, (i, idx) => { 
            if (i.name) formErrors[`accountsAddresses|name|${idx}`] = (this.doesStringContainNonAlphanumeric(i.name)) ? 'Address name contains invalid characters' : null;
            specialCharacterError =  formErrors[`accountsAddresses|name|${idx}`] ? true : specialCharacterError;
        });

        this.setState({formErrors, specialCharacterError})
    }

    checkSubCategory = id => () => {
        let marketing = this.state.formData.marketing;
        if (_.find(marketing, i => i === id)){
            marketing = _.filter(marketing, i => i !== id);
        } else {
            marketing.push(id);
        }
        this.setState({
            formData: {
                ...this.state.formData,
                marketing: marketing
            }
        });
    }

    checkCategory = category => () => {

        let marketing = this.state.formData.marketing;
        let remove = true;

        
        _.each(_.filter(this.state.lists.marketing, {cm_category: category}), m => {
            if (!marketing.includes(m.cm_id)){
                marketing.push(m.cm_id);
                remove = false;
            }
        });
        
        if (remove) {
            _.each(_.filter(this.state.lists.marketing, {cm_category: category}), m => {
                marketing = _.filter(marketing, i => i !== m.cm_id);
            });
        }

        this.setState({
            formData: {
                ...this.state.formData,
                marketing: marketing
            }
        });
    }

    render() {
        const { formData, formErrors, confirmationMsg, access } = this.state;
        const { classes } = this.props;
        if (this.state.redirect) {
            return <Redirect to={{pathname: "/customers/contacts/add", state: { customerId: this.state.customerId }}} />
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Customer
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Business Details
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={[
                                        {value: 'Dealer', label: 'Dealer'},
                                        {value: 'End User', label: 'End User'},
                                        {value: 'Group', label: 'Group'}
                                        ]} 
                                        label='Business Type *'
                                        value={this.state.formData.businessType}
                                        onChange={this.handleSelectChange('businessType')}
                                        error={formErrors && formErrors['businessType'] && true}
                                        errorText={formErrors && formErrors['businessType']}
                                    />
                                </FormControl>
                                <TextField
                                    id="businessName"
                                    name="businessName"
                                    label="Business Name *"
                                    value={this.state.formData.businessName}
                                    className={classes.textField}
                                    error={formErrors && formErrors['businessName'] && true}
                                    helperText={formErrors && formErrors['businessName']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="tradingName"
                                    name="tradingName"
                                    label="Business Trading Name"
                                    value={this.state.formData.tradingName}
                                    className={classes.textField}
                                    error={formErrors && formErrors['tradingName'] && true}
                                    helperText={formErrors && formErrors['tradingName']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={[
                                        {value: 'Limited Company', label: 'Limited Company'},
                                        {value: 'Public Limited Company', label: 'Public Limited Company'},
                                        {value: 'Sole Trader', label: 'Sole Trader'},
                                        {value: 'Partnership', label: 'Partnership'},
                                        {value: 'Consumer', label: 'Consumer'},
                                        {value: 'Other', label: 'Other'}
                                        ]} 
                                        label='Legal Status'
                                        value={this.state.formData.legalStatus}
                                        onChange={this.handleSelectChange('legalStatus')}
                                        error={formErrors && formErrors['legalStatus'] && true}
                                        errorText={formErrors && formErrors['legalStatus']}
                                    />
                                </FormControl>
                                <TextField
                                    id="companyRegistrationNumber"
                                    name="companyRegistrationNumber"
                                    label="Company Registration Number"
                                    value={this.state.formData.companyRegistrationNumber}
                                    className={classes.textField}
                                    error={formErrors && formErrors['companyRegistrationNumber'] && true}
                                    helperText={formErrors && formErrors['companyRegistrationNumber']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="companyVATNumber"
                                    name="companyVATNumber"
                                    label="Company VAT Number"
                                    value={this.state.formData.companyVATNumber}
                                    className={classes.textField}
                                    error={formErrors && formErrors['companyVATNumber'] && true}
                                    helperText={formErrors && formErrors['companyVATNumber']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="companyEORINumber"
                                    name="companyEORINumber"
                                    label="Company EORI Number"
                                    value={this.state.formData.companyEORINumber}
                                    className={classes.textField}
                                    error={formErrors && formErrors['companyEORINumber'] && true}
                                    helperText={formErrors && formErrors['companyEORINumber']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Linked Customers
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteMultiSelect 
                                        options={this.state.lists.customers} 
                                        label='customers'
                                        value={this.state.formData.customers}
                                        onChange={this.handleMultiSelectChange('customers')}
                                        error={formErrors && formErrors['customers'] && true}
                                        errorText={formErrors && formErrors['customers']}
                                    />
                                </FormControl>
                            </Typography>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Account Information
                            </Typography>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.vatList}
                                        label='VAT *'
                                        value={this.state.formData.vatRate}
                                        onChange={this.handleSelectChange('vatRate')}
                                        error={formErrors && formErrors['vatRate'] && true}
                                        errorText={formErrors && formErrors['vatRate']}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.discountList}
                                        label='Discount *'
                                        value={this.state.formData.discountRate}
                                        onChange={this.handleSelectChange('discountRate')}
                                        error={formErrors && formErrors['discountRate'] && true}
                                        errorText={formErrors && formErrors['discountRate']}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.paymentTermsList}
                                        label='Payment Term *'
                                        value={this.state.formData.paymentTerm}
                                        onChange={this.handleSelectChange('paymentTerm')}
                                        error={formErrors && formErrors['paymentTerm'] && true}
                                        errorText={formErrors && formErrors['paymentTerm']}
                                    />
                                </FormControl>
                                {access.viewCreditLimit &&
                                    <Grid container spacing={3} xs={12} style={{marginTop:'1em'}}>
                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                name="creditLimitNet"
                                                label="Credit Limit Net *"
                                                value={this.state.formData.creditLimitNet}
                                                error={formErrors && formErrors['CreditLimitNet'] && true}
                                                helperText={formErrors && formErrors['CreditLimitNet']}
                                                onChange={this.handleChange}
                                                margin="none"
                                                fullWidth
                                                type='number'
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    startAdornment: (
                                                    <InputAdornment  position="start">
                                                        £
                                                    </InputAdornment>
                                                    )
                                                }}
                                                disabled={!access.updateCreditLimit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                name="creditLimitGross"
                                                label="Credit Limit Gross"
                                                value={this.state.formData.creditLimitGross || 0}
                                                margin="none"
                                                fullWidth
                                                type='number'
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    startAdornment: (
                                                    <InputAdornment  position="start">
                                                        £
                                                    </InputAdornment>
                                                    )
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <Typography style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.54)'}}>
                                                If you have more than one accounts address, each will have this credit limit individually
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.accountStatusList}
                                        label='Account Status *'
                                        value={this.state.formData.accountStatus}
                                        onChange={this.handleSelectChange('accountStatus')}
                                        error={formErrors && formErrors['accountStatus'] && true}
                                        errorText={formErrors && formErrors['accountStatus']}
                                    />
                                </FormControl>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 1, label: 'Yes'},
                                                    {value: 0, label: 'No'}
                                                ]}
                                                label='Monthly Service Plan *'
                                                value={this.state.formData.monthlyServicePlan}
                                                onChange={this.handleSelectChange('monthlyServicePlan')}
                                                error={formErrors && formErrors['monthlyServicePlan'] && true}
                                                errorText={formErrors && formErrors['monthlyServicePlan']}
                                                noClear
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 1, label: 'Yes'},
                                                    {value: 0, label: 'No'}
                                                ]}
                                                label='Yearly Service Plan *'
                                                value={this.state.formData.yearlyServicePlan}
                                                onChange={this.handleSelectChange('yearlyServicePlan')}
                                                error={formErrors && formErrors['yearlyServicePlan'] && true}
                                                errorText={formErrors && formErrors['yearlyServicePlan']}
                                                noClear
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Business Registered Address
                            </Typography>
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.countryList} 
                                        label='Country'
                                        onChange={this.handleSelectChange('country')}
                                        error={formErrors && formErrors['country'] && true}
                                        errorText={formErrors && formErrors['country']}
                                        value={this.state.formData.country}
                                    />
                                </FormControl>

                                {this.state.formData.country === 243 && this.state.formData.showAddressLookup && // United Kingdom
                                    <AddressLookup                                     
                                        key={this.state.formId}
                                        handleSelectedAddress={this.handleRegisteredSelectedAddress}
                                        inlineLayout={false}
                                        value={this.state.formData.selectedAddress}
                                    />
                                }
                                <Link component="button" variant="caption" className='blueLink' onClick={this.handleManuallyEnterRegisteredAddress}>
                                    {this.state.formData.showAddressLookup ? 'Manually enter address': 'Use address lookup (UK Only)' }
                                </Link>
                                
                                {(this.state.formData.country !== 243 || (this.state.formData.country === 243 && this.state.formData.showAddress) || this.state.formData.showAddress)  &&
                                    <React.Fragment>
                                        <TextField
                                            id="addressOne"
                                            name="addressOne"
                                            label="Address Line 1 *"
                                            value={this.state.formData.addressOne}
                                            className={classes.textField}
                                            error={formErrors && formErrors['addressOne'] && true}
                                            helperText={formErrors && formErrors['addressOne']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <TextField
                                            id="addressTwo"
                                            name="addressTwo"
                                            label="Address Line 2"
                                            value={this.state.formData.addressTwo}
                                            className={classes.textField}
                                            error={formErrors && formErrors['addressTwo'] && true}
                                            helperText={formErrors && formErrors['addressTwo']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <TextField
                                            id="addressThree"
                                            name="addressThree"
                                            label="Address Line 3"
                                            value={this.state.formData.addressThree}
                                            className={classes.textField}
                                            error={formErrors && formErrors['addressThree'] && true}
                                            helperText={formErrors && formErrors['addressThree']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <TextField
                                            id="town"
                                            name="town"
                                            label="City / Town *"
                                            value={this.state.formData.town}
                                            className={classes.textField}
                                            error={formErrors && formErrors['town'] && true}
                                            helperText={formErrors && formErrors['town']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <TextField
                                            id="postcode"
                                            name="postcode"
                                            label="Postcode"
                                            value={this.state.formData.postcode}
                                            className={classes.textField}
                                            error={formErrors && formErrors['postcode'] && true}
                                            helperText={formErrors && formErrors['postcode']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        {this.state.formData.country === 243 &&
                                            <TextField
                                                id="county"
                                                name="county"
                                                label="County"
                                                value={this.state.formData.county}
                                                className={classes.textField}
                                                error={formErrors && formErrors['county'] && true}
                                                helperText={formErrors && formErrors['county']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                        }
                                    </React.Fragment>
                                }
                                <TextField
                                    id="phone"
                                    name="phone"
                                    label="Phone"
                                    value={this.state.formData.phone}
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={this.state.formData.email}
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="website"
                                    name="website"
                                    label="Website"
                                    value={this.state.formData.website}
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <Grid container spacing={2} style={{alignItems: 'center'}}>
                                    <Grid item xs={12} className='buttonRow' >
                                        <Button onClick={()=> this.checkZones()}> <AllIcon icon={icons.location }/> Check Postcode</Button>
                                    </Grid>
                                    {_.map(_.filter(this.state.couriers, c => c.zones.length > 0), (i, idx) => {
                                        let cz = _.find(this.state.formData.zones, {courier: i.cour_id})
                                        return (
                                            <Grid item container xs={12} spacing={1} style={{alignItems: 'center', borderBottom: (idx + 1) !== _.filter(this.props.couriers, c => c.zones.length > 0).length && `1px solid ${colors.disabled}`}}>
                                                <Grid item>
                                                    <Checkbox 
                                                        checked={!!cz} 
                                                        value={!!cz}
                                                        onChange={()=>this.handleCourierCheck(idx, 'registered')(i.cour_id)}
                                                    />
                                                </Grid>
                                                <Grid item style={{paddingTop: '0.5em'}}>
                                                    <img src={this.getLogoSrc(i.file)} style={{maxWidth: 64}} />
                                                </Grid>    
                                                <Grid item >{i.cour_name}</Grid>
                                                <Grid item xs={5} style={{marginLeft: 'auto'}}>
                                                    <AutoCompleteSelect 
                                                        fullWidth
                                                        options={_.map(i.zones, z => ({label: z.cz_name, value: z.cz_id}))}
                                                        onChange={this.handleCourierSelect(idx, 'registered')(i.cour_id)}
                                                        value={ cz ? cz?.zone : null }
                                                        noClear
                                                        disabled={!cz}
                                                    />
                                                </Grid>
                                            </Grid>    
                                        )}
                                    )}
                                </Grid>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h6" gutterBottom>
                            Trading Addresses
                        </Typography>
                        <Grid container item spacing={3}>
                            {formData.tradingAddresses.map((item, idx) => {
                                return (
                                    <Grid item xs={12} md={9} lg={6} xl={4} key={idx}>
                                        <AddressCard                       
                                            key={this.state.formId}
                                            address={item} 
                                            classes={classes} 
                                            countryList={this.state.countryList} 
                                            countyList={this.state.countyList}
                                            idx={idx}
                                            handleChange={this.handleAddressChange}
                                            formErrors={formErrors}
                                            handleCheckChange={this.handleCheckChange}
                                            handleRemove={this.handleRemoveAddress}
                                            addressType='tradingAddresses'
                                            handleSelectedAddress={this.handleSelectedAddress(idx, 'tradingAddresses')}
                                            useTradingAddress={this.useTradingAddress}
                                            handleManuallyEnterAddress={this.handleManuallyEnterAddress(idx, 'tradingAddresses')}
                                            handleAddressSelectChange={this.handleAddressSelectChange}
                                            addressNameList={this.state.addressNameList}
                                            couriers={this.state.couriers}
                                            handleCourierCheck={this.handleCourierCheck(idx, 'tradingAddresses')}
                                            handleCourierSelect={this.handleCourierSelect(idx, 'tradingAddresses')}
                                            checkZones={()=>this.checkZones(idx, 'tradingAddresses')}
                                        />
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <PaddedPaper className={classes.paperGrey} onClick={this.handleAddTrading}>
                                    <FALightIcon icon='plus' noMargin button style={{width: '100%', fontSize:'30px', height: '100px', color: '#ddd'}} />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h6" gutterBottom>
                            Accounts Addresses
                        </Typography>
                        <Grid container item spacing={3}>
                            {this.state.formData.accountsAddresses.map((item, idx) => {
                                return (
                                    <Grid item xs={12} md={9} lg={6} xl={4} key={idx}>
                                        <AddressCard       
                                            key={this.state.formId}
                                            address={item} 
                                            classes={classes} 
                                            countryList={this.state.countryList} 
                                            countyList={this.state.countyList}
                                            idx={idx}
                                            handleChange={this.handleAddressChange}
                                            formErrors={formErrors}
                                            handleCheckChange={this.handleCheckChange}
                                            handleRemove={this.handleRemoveAddress}
                                            addressType='accountsAddresses'
                                            handleSelectedAddress={this.handleSelectedAddress(idx, 'accountsAddresses')}
                                            tradingAddresses={this.state.tradingList}
                                            useTradingAddress={this.useTradingAddress}
                                            handleManuallyEnterAddress={this.handleManuallyEnterAddress(idx, 'accountsAddresses')}
                                            handleAddressSelectChange={this.handleAddressSelectChange}
                                            addressNameList={this.state.addressNameList}
                                            couriers={this.state.couriers}
                                            handleCourierCheck={this.handleCourierCheck(idx, 'accountsAddresses')}
                                            handleCourierSelect={this.handleCourierSelect(idx, 'accountsAddresses')}
                                            checkZones={()=>this.checkZones(idx, 'accountsAddresses')}
                                        />
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <PaddedPaper className={classes.paperGrey} onClick={this.handleAddAccounts}>
                                    <FALightIcon icon='plus' noMargin button style={{width: '100%', fontSize:'30px', height: '100px', color: '#ddd'}} />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h6" gutterBottom>
                            Site Addresses
                        </Typography>
                        <Grid container item spacing={3}>
                            {this.state.formData.siteAddresses.map((item, idx) => {
                                return (
                                    <Grid item xs={12} md={9} lg={6} xl={4} key={idx}>
                                        <AddressCard       
                                            key={idx}
                                            address={item} 
                                            classes={classes} 
                                            countryList={this.state.countryList} 
                                            countyList={this.state.countyList}
                                            idx={idx}
                                            handleChange={this.handleAddressChange}
                                            formErrors={formErrors}
                                            handleCheckChange={this.handleCheckChange}
                                            handleRemove={this.handleRemoveAddress}
                                            addressType='siteAddresses'
                                            handleSelectedAddress={this.handleSelectedAddress(idx, 'siteAddresses')}
                                            tradingAddresses={this.state.tradingList}
                                            useTradingAddress={this.useTradingAddress}
                                            handleManuallyEnterAddress={this.handleManuallyEnterAddress(idx, 'siteAddresses')}
                                            //AccessTimes req props
                                            handleDayChange={this.handleDayChange}
                                            handleTimeChange={this.handleTimeChange}
                                            handleAddAccessTimes={this.handleAddAccessTimes(idx)}
                                            handleAddAccessTimeRow={this.handleAddAccessTimeRow}
                                            handleRemoveRow={this.handleRemoveRow}
                                            addAccessTimes={item.addAccessTimes}
                                            addOpeningTimes={item.addOpeningTimes}
                                            formData={item}
                                            update={false} 
                                            handleAddressSelectChange={this.handleAddressSelectChange}
                                            addressNameList={this.state.addressNameList}
                                            accountsList={this.state.formData.accountsAddresses}
                                            linkAccount={this.handleLinkAccount}
                                            couriers={this.state.couriers}
                                            handleCourierCheck={this.handleCourierCheck(idx, 'siteAddresses')}
                                            handleCourierSelect={this.handleCourierSelect(idx, 'siteAddresses')}
                                            checkZones={()=>this.checkZones(idx, 'siteAddresses')}
                                            handleNoteChange={this.handleNoteChange}
                                        />
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <PaddedPaper className={classes.paperGrey} onClick={this.handleAddSites}>
                                    <FALightIcon icon='plus' noMargin button style={{width: '100%', fontSize:'30px', height: '100px', color: '#ddd'}} />
                                </PaddedPaper>
                            </Grid>
                            {this.state.specialCharacterError && 
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom style={{color: 'red', textAlign: 'right'}}>
                                        Please remove special characters from the marked fields
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h6" gutterBottom style={{marginBottom: '1em'}}>Customer Marketing</Typography>
                        {_.map(_.uniqBy(this.state.lists.marketing, 'cm_category'), (category, i) => 
                            _.filter(this.state.lists.marketing, i => i.cm_category === category.cm_category && i.cm_name).length > 0 &&
                                <ExpansionPanel key={i}>
                                    <ExpansionPanelSummary dense expandIcon={<AllIcon icon={icons.arrowDown} white noMargin />}>
                                        <Grid container style={{alignItems:'center'}}>
                                            <Grid item>
                                                <CheckBox
                                                    checked={_.filter(_.filter(this.state.lists.marketing, {cm_category: category.cm_category}), i => i?.cm_name && formData.marketing.includes(i.cm_id)).length > 0}
                                                    style={{color:'white', padding: 0}}
                                                    onClick={this.checkCategory(category.cm_category)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography>{category.cm_category}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <List fullWidth style={{width: '100%'}}>
                                            {
                                                _.map(_.filter(this.state.lists.marketing, {cm_category: category.cm_category}), (item, j) =>
                                                    item.cm_name &&
                                                        <ListItem key={`${i}_${j}`} dense fullWidth>
                                                            <ListItemAvatar>
                                                                <CheckBox 
                                                                    color='primary'
                                                                    checked={formData.marketing.includes(item.cm_id)}
                                                                    onClick={this.checkSubCategory(item.cm_id)}
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item.cm_name} />
                                                        </ListItem>
                                                )
                                            }
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel> 
                        )}  
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper className='buttonRow'>
                        <Button onClick={this.handleConfirmationOpen}
                                variant="contained"
                                color="primary"
                                disabled={this.state.isLoading || this.state.specialCharacterError}
                                >
                            Add
                        </Button>
                    </PaddedPaper>
                </Grid>
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.confirmationOpen} 
                    TransitionComponent={Transition}
                    onClose={this.handleConfirmationClose} 
                    scroll="body"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>Add a New Customer?</DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            Are you sure you want to add this new customer?
                            {confirmationMsg.length > 0 &&
                                <>
                                    <br></br><br></br>
                                    <Typography variant="body1"><b>Similar existing customers:</b></Typography>
                                    {confirmationMsg.map((customer, idx) => {
                                        return (<Typography variant="body2" key={idx}>{customer.cust_name}{customer.cust_trading_name && ' | ' + customer.cust_trading_name}</Typography>)
                                    })}
                                </>
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmationClose} variant="outlined" color="default">Cancel</Button>
                        <Button onClick={this.handleConfirmationSuccess} autoFocus variant="contained" color="primary">Yes</Button>
                    </DialogActions>
                </Dialog>
                <ConfirmationDialog 
                    open={this.state.addContactOpen} 
                    success={this.handleAddContactSuccess} 
                    close={this.handleAddContactClose} 
                    title="Add Contact?" 
                    message={`Would you like to add a contact?`}
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new customer'
                />
            </Grid>
        );
    }
}

export default withStyles(styles)(AddCustomer);
